//import * as Chart from 'chart.js';
import { Component, OnInit} from '@angular/core';
import {Chart} from 'chart.js';
//import { NgChartsConfiguration }  from 'ng2-charts';

@Component({
  selector: 'app-grafikonet',
  templateUrl: './grafikonet.component.html',
  styleUrls: ['./grafikonet.component.scss']
})
export class GrafikonetComponent implements OnInit {
  ngOnInit() {
    const canvas = document.getElementById('myChart') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('Could not get 2D context for canvas element');
      return;
    }

    // const chart = new Chart(ctx, {
    //   type: 'line',
    //   data: {
    //     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //     datasets: [{
    //       label: 'Publikimet për njoftim kontrate (B-05)',
    //       data: [12, 19, 3, 5, 2, 3, 7],
    //       fill: false,
    //       borderColor: 'rgb(75, 192, 192)',
    //       tension: 0.1
    //     }]
    //   },
    //   options: {
    //     scales: {
    //       y: {
    //         beginAtZero: true
    //       }
    //     }
    //   }
    // });


    const chart = new Chart(ctx, {
        type: "doughnut",
        data:{
        labels: [
          'Procedurë e hapur: 366146289.11',
          'Procedurë e kufizuar: 451994.35',
          'Konkurs projektimi: 350513.34',
          'Procedurë konkurruese me negociata: 0',
          'E negociuar pa publikim të njoftimit të kontratës: 51998078.42',
          'Kuotimi i çmimeve: 8046879.31',
          'Procedurë për vlerë minimale: 2628461.27'
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [366146289.11, 451994.35, 350513.34, 0, 51998078.42, 8046879.31, 2628461.27],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(100, 205, 86)',
            'rgb(120, 100, 86)',
            'rgb(230, 205, 100)',
            'rgb(128, 205, 100)',
            'rgb(230, 128, 128)'
          ],
          hoverOffset: 20}
        ]}
        ,
      



        options: {
          aspectRatio: 2,
          plugins: {
              legend: {
                  display: true,
                  position:'right',
                  labels: {
                      color: 'rgb(0, 0, 0)'
                  }
              }
          }
        }
      });



        // const chart = new Chart(ctx, {
        //   type: "bar",
        //   data:{
        //     labels: [
        //             'Procedurë e hapur',
        //             'Procedurë e kufizuar',
        //             'Konkurs projektimi',
        //             'Procedurë konkurruese me negociata',
        //             'E negociuar pa publikim të njoftimit të kontratës',
        //             'Kuotimi i çmimeve',
        //             'Procedurë për vlerë minimale'
        //           ],
        //     datasets: [{
        //       label: 'Procedurat gjate vitit 2022',
        //       data: [366146289.11, 451994.35, 350513.34, 0, 51998078.42, 8046879.31, 2628461.27],
        //       backgroundColor: [
        //         'rgba(255, 99, 132, 0.2)',
        //         'rgba(255, 159, 64, 0.2)',
        //         'rgba(255, 205, 86, 0.2)',
        //         'rgba(75, 192, 192, 0.2)',
        //         'rgba(54, 162, 235, 0.2)',
        //         'rgba(153, 102, 255, 0.2)',
        //         'rgba(201, 203, 207, 0.2)'
        //       ],
        //       borderColor: [
        //         'rgb(255, 99, 132)',
        //         'rgb(255, 159, 64)',
        //         'rgb(255, 205, 86)',
        //         'rgb(75, 192, 192)',
        //         'rgb(54, 162, 235)',
        //         'rgb(153, 102, 255)',
        //         'rgb(201, 203, 207)'
        //       ],
        //       borderWidth: 1
        //     }]
          
          
          
        //   },
        
        //   options: {
        //     scales: {
        //       y: {
        //         beginAtZero: true
        //       }
        //     }
        //   },
        
        // });



  }
}