<!-- HEADER -->
<app-header></app-header>

<!-- MAIN -->
<router-outlet></router-outlet>

<!-- Start Back to top -->
<div class="back-to-top js-back-to-top">
    <span class="fa fa-angle-double-up back-to-top__icon"></span>
    <span class="back-to-top__text">TOP</span>
</div>
<!-- End Back to top -->

<!-- FOOTER -->
<app-footer></app-footer>