<!-- Start Footer -->
<footer *ngIf="!mainService.isDemoPage" class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="footer__widget">
                    <img [src]="baseUrl + mainService.siteFooterLogo" class="footer__widget-logo" [attr.alt]="mainService.siteName" />
                    <p>{{mainService.siteFooterContent}}</p>
                    <div *ngFor="let item of mainService?.siteFooterList" class="footer__widget-contact">
                        <i class="{{item.FontAwesomeIcon}} icon"></i>
                        <p>{{item.Text}}</p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-lg-2 col-sm-3">
                <div class="footer__widget">
                    <h4 class="footer__widget-title">Shërbimet</h4>
                    <ul class="footer__widget-linklist">
                        <li><a href="javascript:;">e-prokurimi</a></li>
                        <li><a href="javascript:;">OCDS</a></li>
                        <!--<li><a href="javascript:;">Trajnime</a></li>-->
                        <!--<li><a href="javascript:;">Angular builder</a></li>
                        <li><a href="javascript:;">Worpress theme</a></li>-->
                    </ul>
                </div>
            </div>
            <div class="col-6 col-lg-2 col-sm-3">
                <div class="footer__widget">
                    <h4 class="footer__widget-title">KRPP</h4>
                    <ul class="footer__widget-linklist">
                        <li><a href="javascript:;" routerLink="/about">Rreth nesh</a></li>
                        <li><a href="javascript:;" routerLink="/services">Shërbimet</a></li>
                        <li><a href="javascript:;" routerLink="/career">Na bashkohuni</a></li>
                        <li><a href="javascript:;" routerLink="/contact">Kontakti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-6 col-lg-2 col-sm-3">
                <div class="footer__widget">
                    <h4 class="footer__widget-title">Ndërlidhjet</h4>
                    <ul class="footer__widget-linklist">
                        <li><a href="https://kuvendikosoves.org/eng/home/" target=”_blank” >Kuvendi i kosovës</a></li>
                        <li><a href="https://oshp.rks-gov.net" target=”_blank” >Organi Shqyrtues i Prokurimit</a></li>
                        <li><a href="https://zka-rks.org" target=”_blank” >Zyra Kombëtare e Auditimit</a></li>
                        <li><a href="https://ekosova.rks-gov.net" target=”_blank” >e-Kosova</a></li>
                        <!--<li><a href="javascript:;">Site map</a></li>-->
                    </ul>
                </div>
            </div>
            <div class="col-6 col-lg-3 col-sm-3">
                <div class="footer__widget">
                    <h4 class="footer__widget-title">Na ndiqni</h4>
                    <ul class="footer__widget-network">
                        <li><a href="https://www.facebook.com/profile.php?id=100089577137622" class="footer__widget-network-link"><i class="fa-brands fa-facebook-f"></i></a></li>
                        <!--<li><a href="javascript:;" class="footer__widget-network-link"><i class="fa-brands fa-twitter"></i></a></li>
                        <li><a href="javascript:;" class="footer__widget-network-link"><i class="fa-brands fa-linkedin"></i></a></li>-->
                        <li><a href="https://www.youtube.com/channel/UCH5B28pIpHpsm7XVL0O0Mag" class="footer__widget-network-link"><i class="fa-brands fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="footer__subfooter">
                    <div class="row">
                        <div class="col-md-6">
                            <p>© <a href="javascript:;">KRPP</a> | 2023. All Rights Reserved.</p>
                        </div>
                        <div class="col-md-6 text-right">
                            <ul class="footer__subfooter-liststyle">
                                <li><a href="javascript:;">Terms Of Service</a></li>
                                <li><a href="javascript:;">Privacy Policy</a></li>
                                <li><a href="javascript:;">Help</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->

<!-- Start Demo Footer -->
<footer *ngIf="mainService.isDemoPage"  class="demo__footer">
    <p>© <a href="#">Faqja zyrtare</a> | KRPP. All Rights Reserved.</p>
</footer>
<!-- Start Demo Footer -->