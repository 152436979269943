<!-- Start Pricing -->
<div class="pricing__grid {{className}}">
    <div class="pricing__grid-header">
        <div class="pricing__grid-header--icon">
            <span class="{{fontAwesomIcon}}"></span>
        </div>
        <h2 class="pricing__grid-header-title">{{title}}</h2>
        <div class="pricing__grid-price">
            <!--<p>
                From
                <span class="pricing__grid-price--number"><em class="pricing__grid-price--currency">{{currency}}</em>{{price}}</span>
                <small class="pricing__content-muted">/mo</small>
            </p>-->                           
        </div>
    </div>
    <div class="pricing__grid-content">
        <ul>
            <li *ngFor="let item of priceList" 
                [class.included]="item.Status" 
                [class.unincluded]="!item.Status"
            >
                <i class="fa" 
                    [class.fa-check]="item.Status" 
                    [class.fa-times]="!item.Status"></i> 
                    {{item.ItemName}}
            </li>
        </ul>
    </div>          
    <div class="pricing__grid-action">
        <button type="button" class="btn {{buttonStyle}} btn-rounded btn-icon-right">Më shumë detaje<i class="fa fa-long-arrow-right"></i></button>
    </div>          
</div>
<!-- End Pricing -->