<!-- Start Article -->
<div class="article__card">
    
    <div class="article__card-image">
        <img [src]="coverImage" class="img-fluid" alt="Image article" />
    </div>
    <div class="article__card-heading js-match-height">
        <a 
            *ngFor="let item of categories; let last = last;" 
            routerLink="/njoftimet" 
            [queryParams]="{category: item.attributes.Slug}"
            class="article__card-label"
            [class.last]="last">{{item.attributes.Name}}</a>
            <a href="#" style="text-align: right;">({{date}})</a>
        <!--<a href="{{pageLink}}#disqus_thread" class="article__card-comment"><i class="fa fa-comments"></i> {{commentCounter}}</a>-->
        <h6 class="article__card-title">
            <a style="color:rgb(68, 68, 224)" [routerLink]="pageLink" href="javascript:;">{{title}}</a>
        </h6>

        <div>
            {{detajetstring | slice:0:120 }}...
        </div>
    </div>
    <!--<div class="article__card-footer">
        <div class="article__card-author">
            <img [src]="avatar" class="article__card-avatar" alt="Author Avatar" />
            <p><strong>Postuar nga :  </strong> <i class="fa fa-user"></i> <a href="#">{{user}}</a></p>
            <a href="#">{{date}}</a>
        </div>
    </div>-->         
</div>
<!-- End Article -->