<h4 class="card-title">Kontratat e nënshkruara sipas procedurave </h4> 

<canvas width="100%" height="80%" id="myChart"></canvas>  
<!-- 
<div class="col-lg-4" >
    Procedura e hapur: 50
</div> -->


<!-- <div>
    <canvas id="myChart"></canvas>

    <ul>
      <li>
        Procedurë e hapur: 366146289.11
      </li>
      <li>
        Procedurë e kufizuar: 451994.35
      </li>
      <li>
        Konkurs projektimi: 350513.34
      </li>
      <li>
        Procedurë konkurruese me negociata: 0
      </li>
      <li>
        E negociuar pa publikim të njoftimit të kontratës: 51998078.42
      </li>
      <li>
        Kuotimi i çmimeve: 8046879.31
      </li>
      <li>
        Procedurë për vlerë minimale: 2628461.27
      </li>
    </ul>
  </div> -->