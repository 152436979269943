import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	
	
	/*{
		path: '',
		loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
	},*/
	/*{
		path: '',
		loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule)
	},*/

	{
		path:'', redirectTo: 'njoftimet', pathMatch: 'full'

	},
	{
		path: 'demo',
		loadChildren: () => import('./pages/demo/demo.module').then(m => m.MainModule)
	},
	{
		path: 'about',
		loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule)
	},
	{
		path: 'services',
		loadChildren: () => import('./pages/service/service.module').then(m => m.ServiceModule)
	},
	{
		path: 'pricing',
		loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule)
	},
	{
		path: 'team',
		loadChildren: () => import('./pages/team/team.module').then(m => m.TeamModule)
	},
	{
		path: 'gallery',
		loadChildren: () => import('./pages/gallery/gallery.module').then(m => m.GalleryModule)
	},
	{
		path: 'faq',
		loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule)
	},
	{
		path: 'career',
		loadChildren: () => import('./pages/career/career.module').then(m => m.CareerModule)
	},
	{
		path: 'testimonial',
		loadChildren: () => import('./pages/testimoni/testimoni.module').then(m => m.TestimoniModule)
	},
	{
		path: 'njoftimet',
		loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule)
	},
	{
		path: 'contact',
		loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule)
	},
	{
		path: 'legjislacioni',
		loadChildren: () => import('./pages/legjislacioni/legjislacioni.module').then(m => m.LegjislacioniModule)
	},
	{
		path: 'legjsekondar',
		loadChildren: () => import('./pages/legjsekondar/legjsekondar.module').then(m => m.LegjsekondarModule)
	},
	{
		path: 'interpretimet',
		loadChildren: () => import('./pages/interpretimet/interpretimet.module').then(m => m.InterpretimetModule)
	},
	{
		path: 'direktivatbe',
		loadChildren: () => import('./pages/direktivatbe/direktivatbe.module').then(m => m.DirektivatbeModule)
	},
	{
		path: 'strategjia',
		loadChildren: () => import('./pages/strategjia/strategjia.module').then(m => m.StrategjiaModule)
	},
	{
		path: 'planperformance',
		loadChildren: () => import('./pages/planperformance/planperformance.module').then(m => m.PlanperformanceModule)
	},
	{
		path: 'raportetvjetore',
		loadChildren: () => import('./pages/raportetvjetore/raportetvjetore.module').then(m => m.RaportetvjetoreModule)
	}, 
	{
		path: 'raportetstatistikore',
		loadChildren: () => import('./pages/raportetstatistikore/raportetstatistikore.module').then(m => m.RaportetstatistikoreModule)
	}, 
	{
		path: 'raportetperformance',
		loadChildren: () => import('./pages/raportetperformance/raportetperformance.module').then(m => m.RaportetperformanceModule)
	}, 
	{
		path: 'manualet',
		loadChildren: () => import('./pages/manualet/manualet.module').then(m => m.ManualetModule)
	}, 
	{
		path: 'videomanualeto',
		loadChildren: () => import('./pages/videomanualeto/videomanualetO.module').then(m => m.VideomanualetOModule)
	},  
	{
		path: 'videomanualeta',
		loadChildren: () => import('./pages/videomanualeta/videomanualeta.module').then(m => m.VideomanualetaModule)
	},   
	{
		path: 'menaxhimikontratesv',
		loadChildren: () => import('./pages/menaxhimikontratesv/menaxhimikontratesv.module').then(m => m.MenaxhimikontratesvModule)
	},    
	{
		path: 'menaxhimikontratest',
		loadChildren: () => import('./pages/menaxhimikontratest/menaxhimikontratest.module').then(m => m.MenaxhimikontratestModule)
	}, 
	{
		path: 'rregulloretkrpp',
		loadChildren: () => import('./pages/rregulloretkrpp/rregulloretkrpp.module').then(m => m.RregulloretkrppModule)
	}, 
	{
		path: 'shqyrtimianulimit',
		loadChildren: () => import('./pages/shqyrtimianulimit/shqyrtimianulimit.module').then(m => m.ShqyrtimianulimitModule)
	},
	{
		path: 'bashkpunimet',
		loadChildren: () => import('./pages/bashkpunimet/bashkpunimet.module').then(m => m.BashkpunimetModule)
	},
	{
		path: 'listaetrajnereve',
		loadChildren: () => import('./pages/listaetrajnereve/listaetrajnereve.module').then(m => m.ListaetrajnereveModule)
	},
	{
		path: 'listaezyrtareve',
		loadChildren: () => import('./pages/listaezyrtareve/listaezyrtareve.module').then(m => m.ListaezyrtareveModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
