<!-- Start Section -->
<section class="section section__cta pb-80">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-4">
                <div class="column__boxed">
                    <span class="icon icon-help column__boxed-icon"></span>
                    <h5 class="column__boxed-title">Get Support</h5>
                    <p>
                    Homero sapientem per id, id sonet veniam commune vis, ei nam non
                    </p>
                    <a href="#" class="btn-link">Go to support</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-4">
                <div class="column__boxed">
                    <span class="icon icon-users column__boxed-icon"></span>
                    <h5 class="column__boxed-title">Our comumnity</h5>
                    <p>
                    Homero sapientem per id, id sonet veniam commune vis, ei nam non
                    </p>
                    <a href="#" class="btn-link">Go to comumnity</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-4">
                <div class="column__boxed">
                    <span class="icon icon-question column__boxed-icon"></span>
                    <h5 class="column__boxed-title">F.A.Q</h5>
                    <p>
                    Homero sapientem per id, id sonet veniam commune vis, ei nam non
                    </p>
                    <a href="#" class="btn-link">Go to FAQ</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Section -->