<!-- Start Section Call to Action -->
<section class="section section__cta">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <h2>{{text}}</h2>
                <a href="https://e-prokurimi.rks-gov.net" target=”_blank” class="btn btn-white btn-rounded btn-lg btn-icon-right">e-Prokurimi <i class="fa fa-long-arrow-right"></i></a>
            </div>
        </div>
    </div>
</section>
<!-- End Section Call to Action -->