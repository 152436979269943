import { Component, OnInit } from '@angular/core';
import { MainService } from '@services';
import { environment } from '@environments';


import { ActivatedRoute, Route, Router } from '@angular/router';
//import { Observable } from 'rxjs';

declare var smoothScripts: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	public baseUrl: string = environment.baseUrl;

	langVariable: string = "";
	langSelectedValue: string = "";

	//id: Observable<string>;
	//id: string;


	options=[
		{value: 'AL', label:'AL'},
		{value: 'EN', label:'EN'},
		{value: 'SR', label:'SR'}
	]

	
	
	constructor(
		public mainService: MainService,
		private router: Router,
		private route: ActivatedRoute
	) {
	}

	ngOnInit(): void {
			
		
		console.log('test');

		console.log(this.route.queryParams);
		 this.route.queryParams
		.subscribe(params => {
		  this.langVariable = params['language'];
		  console.log(this.langVariable);
		  if (this.langVariable === undefined) {
			this.langVariable = "SQ";
		  }
	   })


/*
		//if (this.route.snapshot.queryParams['language']) 
		//{
			this.route.queryParams
			.subscribe(params => {
				this.langVariable = params['language'];
			})

			
			alert(this.langVariable);
		//}
		
		if (this.langVariable =="")
		{
			this.langVariable = "EN";
		}
		
		alert(this.langVariable);
		
		 this.id$ = this.route.paramMap.pipe(map(paramMap => paramMap.get('id')));
		*/
		//this.langVariable='SQ';
		// if(this.langVariable !=='')
		// {
		// 	//this.langVariable = 'AL';
		// 	this.langSelectedValue = this.langVariable;

		// }
		// else
		// {
		// 	this.langVariable = this.langVariable;
		// 	this.langSelectedValue = this.langVariable;
		// }
	}

	ngAfterViewInit(){
		// ====================== //
		// Init Layout 
		// ====================== //
		if(this.mainService.isBrowser){
			smoothScripts.navigation();
			smoothScripts.scroll();
		}


	}

	onSelectionChanged(e: any)
	{
		
		//this.route.url
		
		//alert('no query param called "foo"!');
		this.langVariable = e;
		this.langSelectedValue = e;

		// //console.log('sq');

		// //this.route.queryParams.subscribe(params=>{
		// //	this.langVariable = params['language'];
		// //})
		// //this.router.navigate(['/home'], { queryParams: { language:  this.langVariable}});

		// this.router.navigate(['/njoftimet'], { queryParams: { language:  e}})
		this.router.navigate(
			[], 
			{
			  relativeTo: this.route,
			  queryParams: { language: this.langVariable },
			  queryParamsHandling: 'merge'
			});
	}

}
