<!-- Start Article -->
<div class="article__recent">
    <img [src]="coverImage" class="img-fluid" alt="" />
    <div class="article__recent-content">
        <div class="article__card-heading">
        <a 
            *ngFor="let itemCat of categories; let last = last;" 
            routerLink="/njoftimet" 
            [queryParams]="{category: itemCat.attributes.Slug}"
            class="article__card-label"
            [class.last]="last">{{itemCat.attributes.Name}}</a> 
            <a href="{{pageLink}}#disqus_thread" class="article__card-comment"><i class="fa fa-comments"></i> {{commentCounter}}</a>
            <h4 class="article__card-title"><a [routerLink]="pageLink">{{title}}</a></h4>
        </div>
        <div class="article__card-footer">
            <div class="article__card-author">
                <img [src]="avatar" class="article__card-avatar" [attr.alt]="user" />
                <p><strong>Posted by :</strong> <i class="fa fa-user"></i> <a href="#">{{user}}</a></p>
                <a href="#">{{date}}</a>
            </div>
            <a [routerLink]="pageLink" class="article__card-action btn-link">Read more</a>
        </div> 
    </div>
</div>
<!-- End Article -->