<!-- Start Post Listing -->
<div class="blog__listing">
    <div class="blog__listing-thumbnail">
        <a href="#">
            <img [src]="coverImage" class="img-fluid" alt="" class="blog__listing-thumbnail-equirect"/>
        </a>
    </div>
    <h3 class="blog__listing-title">
        <a [routerLink]="pageLink" href="javascript:;">{{title}}</a>
    </h3>
    <ul class="blog__listing-meta">
        <li>
            <label><i class="fa fa-user"></i> Author : </label>
            <a href="#">{{user}}</a>
        </li>
        <li>
            <label><i class="fa fa-calendar"></i> Publish : </label>
            <a href="#">{{date}}</a>
        </li>
        <li>
            <label><i class="fa fa-comments"></i> Commnents : </label>
            <a href="#">{{commentCounter}}</a>
        </li>
    </ul>
    <div class="blog__listing-content">
        <div class="blog__listing-content--text">
            <p>{{sortDescription}}</p>
        </div>
        <ul class="blog__listing-content--tags">
            <li *ngFor="let item of tags">
                <a [routerLink]="path" [queryParams]="{tag: item.attributes.Slug}">{{item.attributes.Label}}</a>
            </li>
        </ul>
    </div>
    <a [routerLink]="pageLink" href="javascript:;" class="btn-link">Read more</a>
</div>
<!-- End Post Listing -->