import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { MainService } from '@services';
import { FooterComponent, HeaderComponent } from '@components';

import { NgChartsModule } from 'ng2-charts';	
//import { LegjislacioniComponent } from './pages/legjislacioni/legjislacioni.component';

/*import { FormsModule } from '@angular/forms';*/

@NgModule({
	declarations: [
		AppComponent,
		FooterComponent,
		HeaderComponent,
 // LegjislacioniComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		TransferHttpCacheModule,
		AppRoutingModule,
		HttpClientModule,
		NgChartsModule,
		/*,
		FormsModule*/
	],
	providers: [MainService],
	bootstrap: [AppComponent]
})
export class AppModule { }
